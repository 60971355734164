export const AdminRoutes = [
    {
        name: 'SAQ Menu',
        icon: '',
        route: '/dashboard',
        isDisabled: false,
        children: [
            {
                name: 'Site Master',
                route: '/dashboard',
                visible: false,
                stepNo: '1',
                icon: 'fas fa-tv text-blue',
            }, {
                name: 'Tele-Varifaction',
                route: '/tele-verification',
                visible: false,
                stepNo: '2',
                icon: 'fas fa-bars text-success',
            }, {
                name: 'Vendor Allocation',
                route: '/vendor-allocation',
                visible: false,
                stepNo: '3',
                icon: 'fas fa-user text-info',
            }, {
                name: 'Hunter Allocation',
                route: '/hunter-allocation',
                visible: false,
                stepNo: '4',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Site Acquaisation map',
                route: '/site-acquisition-map',
                visible: false,
                stepNo: '5',
                icon: 'fas fa-map-marker text-orange',
            }, {
                name: 'Survay Report',
                route: '/survey-report',
                visible: false,
                stepNo: '6',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Technical Site Survey Report',
                route: '/technical-site-survey-report',
                visible: false,
                stepNo: '7',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'GLA',
                route: '/GLA',
                visible: false,
                stepNo: '8',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Payments',
                route: '/lease-payment',
                visible: false,
                stepNo: '9',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'BP-Application',
                route: '/BP-application',
                visible: false,
                stepNo: '10',
                icon: 'fas fa-copy text-purple',
            },
        ]
    },
    {
        name: 'O&M Menu',
        icon: '',
        route: '/o_m',
        isDisabled: false, 
        children: [
            {
                name: 'Technical Site Survey Report',
                route: '/o_m_technical-site-survey-report',
                visible: false,
                stepNo: '300',
                icon: 'fas fa-copy text-purple',
            },
        ]
    }, {
        name: 'Project Menu',
        icon: '',
        route: '/cad-drawing',
        isDisabled: false,
        children: [
            {
                name: 'CAD Drawing',
                route: '/cad-drawing',
                visible: false,
                stepNo: '71',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Project Vendor Allocation',
                route: '/project-vendor-allocation',
                visible: false,
                stepNo: '72',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Project Hunter Allocation',
                route: '/project-hunter-allocation',
                visible: false,
                stepNo: '73',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'MRN Report',
                route: '/mrn-report',
                visible: false,
                stepNo: '74',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Material delivery confirmation',
                route: '/material-delivery-confirmation',
                visible: false,
                stepNo: '11',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Daily Progress report',
                route: '/dailyprogress-report',
                visible: false,
                stepNo: '12',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Fencing',
                route: '/fencing',
                visible: false,
                stepNo: '13',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Solar structure',
                route: '/solar-structure',
                visible: false,
                stepNo: '14',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Genset platform  ',
                route: '/genset-platform',
                visible: false,
                stepNo: '15',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Power Equipment Installation',
                route: '/power-equipment-installation',
                visible: false,
                stepNo: '16',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Cable tretching ',
                route: '/fable-trenching',
                visible: false,
                stepNo: '17',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'First Pole Installation ',
                route: '/first-pole-installation',
                visible: false,
                stepNo: '18',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Earth Pit ',
                route: '/earth-pit',
                visible: false,
                stepNo: '19',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'PV Installation ',
                route: '/battery-installation',
                visible: false,
                stepNo: '20',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Genset Installation',
                route: '/genset-Installation',
                visible: false,
                stepNo: '22',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Cabling Installation',
                route: '/cabling-installation',
                visible: false,
                stepNo: '23',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Pole and Cabling',
                route: '/pole-and-Cabling',
                visible: false,
                stepNo: '24',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Tower End Equipment Installation And Cabling',
                route: '/tower-end-equipment-installation-and-cabling',
                visible: false,
                stepNo: '25',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Asset Tracking and Surveillance TEE Installation',
                route: '/asset-Tracking-and-Surveillance-TEE-installation',
                visible: false,
                stepNo: '26',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Asset Verification and Surveillance Power Plant',
                route: '/asset-Verification-and-Surveillance-Power-Plant',
                visible: false,
                stepNo: '27',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Pre Commission',
                route: '/pre-commissioning',
                visible: false,
                stepNo: '28',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Site commissioning',
                route: '/site-commissioning',
                visible: false,
                stepNo: '29',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'PAC and FAC',
                route: '/pre-commissioning-and-fac-submission',
                visible: false,
                stepNo: '30',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Ready for Utilization Date',
                route: '/ready-for-Utilization-date',
                visible: false,
                stepNo: '31',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'commercial Operation Date',
                route: '/commercial-Operation-date',
                visible: false,
                stepNo: '32',
                icon: 'fas fa-copy text-purple',
            },
        ]
    }, {
        name: 'Admin',
        icon: '',
        route: '/dashboard',
        isDisabled: false,
        children: [
            {
                name: 'Emp-controller',
                route: '/dashboard',
                visible: false,
                stepNo: '1',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Role-id-controller',
                route: '/dashboard',
                visible: false,
                stepNo: '1',
                icon: 'fas fa-copy text-purple',
            }, {
                name: 'Users-controller',
                route: '/dashboard',
                visible: false,
                stepNo: '1',
                icon: 'fas fa-copy text-purple',
            },
        ]
    },
    {
        name: 'O & M',
        icon: '',
        route: '/om-technical-site-survey-report',
        isDisabled: false,
        children: [
            {
                name: 'TSSR',
                route: '/om-technical-site-survey-report',
                visible: false,
                stepNo: '38',
                icon: 'fas fa-copy text-purple',
            }
        ]
    },
    {
        name: 'Design Menu',
        icon: '',
        route: '/d-technical-site-survey-report',
        isDisabled: false,
        children: [
            {
                name: 'TSSR',
                route: '/d-technical-site-survey-report',
                visible: false,
                stepNo: '39',
                icon: 'fas fa-copy text-purple',
            },
            {
                name: 'DDD',
                route: '/ddd-drawing',
                visible: false,
                stepNo: '40',
                icon: 'fas fa-copy text-purple',
            }
        ]
    },
]
