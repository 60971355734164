import { Component, OnInit } from '@angular/core';
import { DataService } from "../../services/data.service";
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import {
  HttpClient,
  HttpHeaders,
  HttpEventType,
  HttpRequest,
  HttpErrorResponse,
  HttpEvent,
  HttpParams
} from "@angular/common/http";
import { Http } from "@angular/http";
import { Injectable } from "@angular/core";
import { Router, NavigationExtras } from "@angular/router";
import { environment } from "../../../environments/environment";
import { catchError, map, tap } from "rxjs/operators";
import { AdminRoutes } from './utility';
import { Observable } from 'rxjs';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  stepNo: string;
  visible: boolean;
}

export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Site Master', icon: 'ni-tv-2 text-blue', class: '', stepNo: '1', 'visible': false },
  // { path: '/tele-verification', title: 'Site Tele-Verification', icon: 'ni-bullet-list-67 text-success', class: '', stepNo: '2', 'visible': false },
  //{ path: '/vendor-allocation', title: 'Vendor Allocation', icon: 'ni-single-02 text-info', class: '', stepNo: '3', 'visible': false },
  { path: '/hunter-allocation', title: 'SAQ Allocation', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '4', 'visible': false },
  // { path: '/site-acquisition-map', title: 'Site Acquisition Map ', icon: 'ni-pin-3 text-orange', class: '', stepNo: '5', 'visible': false },
  // { path: '/survey-report', title: 'Grid Evaluation Report', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '6', 'visible': false },
  { path: '/tssr', title: 'TSSR', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '6', 'visible': false },
  { path: '/tssr-review', title: 'Review TSSR & Meter Installation', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '3', 'visible': false },
  { path: '/verification-proposal-solar-requirement', title: 'Verification & Proposed Solar Requirement', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '3', 'visible': false },
  { path: '/client-approval', title: 'Client Approval', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '3', 'visible': false },

  { path: '/sar', title: 'Site Acquisition Report', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '7', 'visible': false },
  { path: '/sar-approval', title: 'SAR Approval', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '7', 'visible': false },
  { path: '/legal-sar-approval', title: 'Legal Review & Approval', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '7', 'visible': false },

  { path: '/GLA', title: 'GLA Request', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '8', 'visible': false },
  { path: '/gla-document', title: 'GLA Document', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '8', 'visible': false },

  { path: '/lease-payment', title: 'Payments', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '9', 'visible': false },
  { path: '/BP-application', title: 'Build Permit', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '10', 'visible': false },
  { path: '/other-ways-use-doc', title: 'Other Ways Use Documents', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '33', 'visible': false },

]
export const LEGALROUTES: RouteInfo[] = [
  { path: '/GLA', title: 'GLA Request', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '8', 'visible': false },
  { path: '/gla-document', title: 'GLA Document', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '8', 'visible': false }

]
export const ROUTES1: RouteInfo[] = [
  // { path: '/cad-drawing', title: 'CAD Drawing', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '71', 'visible': false },
  { path: '/project-vendor-allocation', title: 'Project Vendor Allocation', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '72', 'visible': false },
  { path: '/project-hunter-allocation', title: 'Supervisor Allocation', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '73', 'visible': false },
  { path: '/mrn-report', title: 'MRN Report', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '74', 'visible': false },
  { path: '/material-delivery-confirmation', title: 'Material delivery confirmation', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '11', 'visible': false },
  { path: '/dailyprogress-report', title: 'Daily Progress report', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '12', 'visible': false },
  { path: '/pre-commissioning', title: 'Pre commissioning', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '28', 'visible': false },
  { path: '/site-commissioning', title: 'Site commissioning', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '29', 'visible': false },
  { path: '/pre-commissioning-pac', title: 'PAC', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '30', 'visible': false },
  { path: '/pre-commissioning-fac', title: 'FAC', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '30', 'visible': false },
  { path: '/ready-for-Utilization-date', title: 'Ready for Utilization', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '31', 'visible': false },
  { path: '/commercial-Operation-date', title: 'commercial Operation Date', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '32', 'visible': false },
];

export const ROUTES3: RouteInfo[] = [

  { path: '/user-creation-module', title: 'User Creation', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '34', 'visible': false },
  { path: '/master-table', title: 'Employee Master', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '35', 'visible': false },

]
export const ROUTES4: RouteInfo[] = [

  { path: '/reports-summary', title: 'Reports Summary', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '36', 'visible': false },
  { path: '/overall-summary', title: 'Overall Summary', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '37', 'visible': false },

]

export const ROUTES5: RouteInfo[] = [

  { path: '/om-technical-site-survey-report', title: 'Technical Site Survey Report', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '38', 'visible': false },
  // { path: '/overall-summary', title: 'Overall Summary', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '37', 'visible': false },

]

export const ROUTES6: RouteInfo[] = [

  // { path: '/reports-summary', title: 'Reports Summary', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '36', 'visible': false },
  // { path: '/overall-summary', title: 'Overall Summary', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '37', 'visible': false },
  { path: '/d-technical-site-survey-report', title: 'Technical Site Survey Report', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '39', 'visible': false },
  { path: '/ddd-drawing', title: 'DDD Drawing', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '40', 'visible': false },
]

export const MRN1ROUTES: RouteInfo[] = [
  { path: '/fencing', title: 'Fencing ', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '13', 'visible': false },
  { path: '/solar-structure', title: 'Solar Structure ', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '14', 'visible': false },
  { path: '/genset-platform', title: 'Genset Platform ', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '15', 'visible': false },
  { path: '/power-equipment-installation', title: 'Power Equipment Installation', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '16', 'visible': false },
  { path: '/fable-trenching', title: 'Cable Trenching', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '17', 'visible': false },
]
export const MRN2ROUTES: RouteInfo[] = [
  { path: '/first-pole-installation', title: 'First Pole Installation', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '18', 'visible': false },
  { path: '/earth-pit', title: 'Earth Pit ', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '19', 'visible': false },
  { path: '/PV-installation', title: 'PV Installation ', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '20', 'visible': false },
  { path: '/battery-installation', title: 'Battery Installation', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '21', 'visible': false },
  { path: '/genset-Installation', title: 'Genset Installation', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '22', 'visible': false },
  { path: '/cabling-installation', title: 'Cabling Installation', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '23', 'visible': false },
  { path: '/pole-and-Cabling', title: 'Pole and Cabling', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '24', 'visible': false },
  { path: '/tower-end-equipment-installation-and-cabling', title: 'Tower End Equipment Installation And Cabling', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '25', 'visible': false },
  { path: '/asset-Tracking-and-Surveillance-TEE-installation', title: 'Asset Tracking and Surveillance TEE Installation', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '26', 'visible': false },
  { path: '/asset-Verification-and-Surveillance-Power-Plant', title: 'Asset Verification and Surveillance Power Plant', icon: 'ni-single-copy-04 text-purple', class: '', stepNo: '27', 'visible': false },
]

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  Navigation = AdminRoutes;

  public menuItems: any[];
  public menuItems1: any[];

  public mrn1: any[];
  public mrn2: any[];
  menuItems3: any[];
  menuItems4: any[];
  menuItems5: any[];
  menuItems6: any[];
  legalTeamMenu: any[];
  public isCollapsed = true;
  isActivityStepVisible = false;
  baseUrl = "" + environment.devBaseAPIUrl;
  isLoggedIn$: Observable<boolean>;

  constructor(private http: Http, private dataService: DataService, private router: Router) { }

  callSetAccess(stepNo, data?) {
    this.dataService.setAccess(stepNo);
  }

  AdministratorObjAccess = {
    "role_id": "1",
    "roleName": "Administrator",
    "read_access": [1, 2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "write_access": [1, 2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "edit_access": [1, 2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "delete_access": [1, 2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "approval_access": [1, 2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 33, 300, 301]
  }
  BD_TEAMObjAccess = {
    "role_id": "2",
    "roleName": "BD_TEAM",
    "read_access": [1, 2, 3, 4],
    "write_access": [1],
    "edit_access": [1],
    "delete_access": [1],
    "approval_access": [1]
  }
  SAQ_MANAGERObjAccess = {
    "role_id": "3",
    "roleName": "SAQ_MANAGER",
    "read_access": [1, 2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "write_access": [1, 2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "edit_access": [1, 2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "delete_access": [1, 2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "approval_access": [1, 2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301]
  }
  SAQ_TEAMObjAccess = {
    "role_id": "4",
    "roleName": "SAQ_TEAM",
    "read_access": [1, 2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "write_access": [2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "edit_access": [2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "delete_access": [2, 3, 4, 5, 6, 7, 7.5, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "approval_access": []
  }
  PROJECT_MANAGERObjAccess = {
    "role_id": "5",
    "roleName": "PROJECT_MANAGER",
    "read_access": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "write_access": [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "edit_access": [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "delete_access": [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301],
    "approval_access": [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 300, 301]
  }
  PROJECT_TEAMObjAccess = {
    "role_id": "6",
    "roleName": "PROJECT_TEAM",
    "read_access": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 300, 301],
    "write_access": [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 300, 301],
    "edit_access": [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 300, 301],
    "delete_access": [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 300, 301],
    "approval_access": []
  }
  LEGAL_TEAMObjAccess = {
    "role_id": "7",
    "roleName": "LEGAL_TEAM",
    "read_access": [8, 9],
    "write_access": [8, 9],
    "edit_access": [8, 9],
    "delete_access": [8, 9],
    "approval_access": [8, 9]
  }
  VENDORObjAccess = {
    "role_id": "8",
    "roleName": "VENDOR",
    "read_access": [4, 5, 6, 7, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 300, 301],
    "write_access": [4, 6, 7, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 300, 301],
    "edit_access": [4, 6, 7, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 300, 301],
    "delete_access": [4],
    "approval_access": []
  }
  OandmManagerObjAccess = {
    "role_id": "9",
    "roleName": "O&M_MANAGER",
    "read_access": [1, 2, 3, 4, 5, 6, 7, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 300, 301],
    "write_access": [],
    "edit_access": [],
    "delete_access": [],
    "approval_access": []
  }
  HunterObjAccess = {
    "role_id": "10",
    "roleName": "HUNTER",
    "read_access": [6, 7, 8, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 300, 301],
    "write_access": [6, 7, 8, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 300, 301],
    "edit_access": [6, 7, 8, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 300, 301],
    "delete_access": [],
    "approval_access": []
  }
  currentUserRole = {
    "role_id": "",
    "roleName": "",
    "read_access": [],
    "write_access": [],
    "edit_access": [],
    "delete_access": [],
    "approval_access": [],
  };

  ngOnInit() {
    this.isLoggedIn$ = this.dataService.getIsLoggedIn;
    this.isLoggedIn$.subscribe(data => {
      console.log('getIsLoggedIn', data);
      this.menuItems = [];
      this.menuItems1 = [];
      this.mrn1 = [];
      this.mrn2 = [];
      this.menuItems3 = [];
      this.menuItems4 = [];
      this.legalTeamMenu = []
      this.currentUserRole.read_access = [];
      this.currentUserRole.edit_access = [];
      this.currentUserRole.write_access = [];
      this.currentUserRole.delete_access = [];
      this.currentUserRole.approval_access = [];
      if (data == true) {
        this.setAccess()
        this.router.events.subscribe((event) => {
          this.isCollapsed = true;
        });
      }
    })
  }

  setAccess() {
    this.dataService.currentUser.subscribe((response) => {
      console.log("assigning access from http response", response);
      localStorage.setItem('readAccess', JSON.stringify(response['result'].readAccess));

      this.currentUserRole.read_access = response['result'].readAccess;
      this.currentUserRole.edit_access = response['result'].editAccess;
      this.currentUserRole.write_access = response['result'].writeAccess;
      this.currentUserRole.delete_access = response['result'].deleteAccess;
      this.currentUserRole.approval_access = response['result'].approvalAccess;

      let tempMenus = [...ROUTES.filter(menuItem => menuItem)];
      let tempMenus1 = [...ROUTES1.filter(menuItem1 => menuItem1)];
      let legal_TeamMenu = [...LEGALROUTES.filter(menuItem1 => menuItem1)]

      let mrn1 = [...MRN1ROUTES.filter(mrn => mrn)];
      let mrn2 = [...MRN2ROUTES.filter(mrn => mrn)];

      let tempMenus3 = [...ROUTES3.filter(menuItem1 => menuItem1)];
      let tempMenus4 = [...ROUTES4.filter(menuItem1 => menuItem1)];
      let tempMenus5 = [...ROUTES5.filter(menuItem1 => menuItem1)];
      let tempMenus6 = [...ROUTES6.filter(menuItem1 => menuItem1)];

      tempMenus.forEach((obj, originIndex) => {
        if (this.currentUserRole.read_access.indexOf(obj.stepNo) != -1) {
          obj['visible'] = true;
          if ((originIndex >= 12 && originIndex <= 22) || (originIndex >= 25 && originIndex <= 28)) {
            this.isActivityStepVisible = true;
          }
        } else {
          obj.visible = false;
        }
      });
      legal_TeamMenu.forEach((obj, originIndex) => {
        if (this.currentUserRole.read_access.indexOf(obj.stepNo) != -1) {
          obj['visible'] = true;
          if ((originIndex >= 12 && originIndex <= 22) || (originIndex >= 25 && originIndex <= 28)) {
            this.isActivityStepVisible = true;
          }
        } else {
          obj.visible = false;
        }
      });
      tempMenus1.forEach((obj, originIndex) => {
        if (this.currentUserRole.read_access.indexOf(obj.stepNo) != -1) {
          obj['visible'] = true;
          if ((originIndex >= 12 && originIndex <= 22) || (originIndex >= 25 && originIndex <= 28)) {
            this.isActivityStepVisible = true;
          }
        } else {
          obj.visible = false;
        }
      });

      tempMenus3.forEach((obj, originIndex) => {
        if (this.currentUserRole.read_access.indexOf(obj.stepNo) != -1) {
          obj['visible'] = true;
          if ((originIndex >= 12 && originIndex <= 22) || (originIndex >= 25 && originIndex <= 28)) {
            this.isActivityStepVisible = true;
          }
        } else {
          obj.visible = false;
        }
      });

      tempMenus4.forEach((obj, originIndex) => {
        if (this.currentUserRole.read_access.indexOf(obj.stepNo) != -1) {
          obj['visible'] = true;
          if ((originIndex >= 12 && originIndex <= 22) || (originIndex >= 25 && originIndex <= 28)) {
            this.isActivityStepVisible = true;
          }
        } else {
          obj.visible = false;
        }
      });

      tempMenus5.forEach((obj, originIndex) => {
        if (this.currentUserRole.read_access.indexOf(obj.stepNo) != -1) {
          obj['visible'] = true;
          if ((originIndex >= 12 && originIndex <= 22) || (originIndex >= 25 && originIndex <= 28)) {
            this.isActivityStepVisible = true;
          }
        } else {
          obj.visible = true;
        }
      });

      tempMenus6.forEach((obj, originIndex) => {
        if (this.currentUserRole.read_access.indexOf(obj.stepNo) != -1) {
          obj['visible'] = true;
          if ((originIndex >= 12 && originIndex <= 22) || (originIndex >= 25 && originIndex <= 28)) {
            this.isActivityStepVisible = true;
          }
        } else {
          obj.visible = true;
        }
      });

      mrn1.forEach((obj, originIndex) => {
        if (this.currentUserRole.read_access.indexOf(obj.stepNo) != -1) {
          obj['visible'] = true;
          if ((originIndex >= 12 && originIndex <= 22) || (originIndex >= 25 && originIndex <= 28)) {
            this.isActivityStepVisible = true;
          }
        } else {
          obj.visible = false;
        }
      });

      mrn2.forEach((obj, originIndex) => {
        if (this.currentUserRole.read_access.indexOf(obj.stepNo) != -1) {
          obj['visible'] = true;
          if ((originIndex >= 12 && originIndex <= 22) || (originIndex >= 25 && originIndex <= 28)) {
            this.isActivityStepVisible = true;
          }
        } else {
          obj.visible = false;
        }
      });

      this.menuItems = tempMenus;
      this.menuItems1 = tempMenus1;
      this.menuItems3 = tempMenus3;
      this.menuItems4 = tempMenus4;
      this.menuItems5 = tempMenus5;
      this.legalTeamMenu = legal_TeamMenu;
      this.menuItems6 = tempMenus6;
      this.mrn1 = mrn1
      this.mrn2 = mrn2
      // console.log(this.o_m_menu )
      console.log(this.menuItems)
    });

  }

}


