import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  accessObj: { read_access: boolean; write_access: boolean; edit_access: boolean; delete_access: boolean; approval_access: boolean; };

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.dataService.getAccessObj().subscribe(
      response => {
        let currentUserRole = {
        };
        currentUserRole['approval_access'] = response['result'].approvalAccess;
        currentUserRole['write_access'] = response['result'].writeAccess;
        currentUserRole['read_access'] = response['result'].readAccess;
        currentUserRole['edit_access'] = response['result'].editAccess;
        currentUserRole['delete_access'] = response['result'].deleteAccess;
        this.accessObj = this.dataService.setAccess1(30, currentUserRole);
        console.log("accessobjis", this.accessObj);
      });
  }

}
