import { Injectable } from "@angular/core";
import { Router, NavigationExtras, ActivatedRoute } from "@angular/router";
import { environment } from "../../environments/environment";
import Swal from 'sweetalert2';

import {
  HttpClient,
  HttpHeaders,
  HttpEventType,
  HttpRequest,
  HttpErrorResponse,
  HttpEvent,
  HttpParams
} from "@angular/common/http";
import { catchError, map, take, tap, distinctUntilChanged } from "rxjs/operators";
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { MatSnackBar } from "@angular/material";
import { ObservableInput, throwError, BehaviorSubject, Observable, of } from "rxjs";

@Injectable({
  providedIn: "root"
})

export class DataService {
  private loggedIn = new BehaviorSubject<boolean>(true);

  getIsLoggedIn = this.loggedIn.asObservable();
  accessObjResponse: any;

  private currentUserSubject = new BehaviorSubject<any>({} as any);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  constructor(private router: Router, private http: HttpClient, public snackBar: MatSnackBar, private activatedRoute: ActivatedRoute) { }

  accessObj = {
    "read_access": false,
    "write_access": false,
    "edit_access": false,
    "delete_access": false,
    "approval_access": false
  };

  private currentUserRole = {
    "role_id": "",
    "roleName": "",
    "read_access": [],
    "write_access": [],
    "edit_access": [],
    "delete_access": [],
    "approval_access": [],
  };

  //   routesInfo= [
  //     { path: '/dashboard', title: 'Site Master',  icon:'ni-tv-2 text-blue', class: '' ,stepNo:'1','visible':false},
  //     { path: '/tele-verification', title: 'Site-Tele-Verification',  icon:'ni-bullet-list-67 text-success', class: '',stepNo:'2' ,'visible':false},
  //     { path: '/vendor-allocation', title: 'Vendor Allocation',  icon:'ni-single-02 text-info', class: '' ,stepNo:'3','visible':false},
  //     { path: '/hunter-allocation', title: 'Hunter Allocation',  icon:'ni-single-copy-04 text-purple', class: '' ,stepNo:'4','visible':false},
  //     { path: '/site-acquisition-map', title: 'Site Acquisition Map ',  icon:'ni-pin-3 text-orange', class: '',stepNo:'5','visible':false},
  //     { path: '/survey-report', title: 'Survey Report ',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'6','visible':false},
  //     { path: '/technical-site-survey-report', title: 'Technical Site Survey Report',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'7' ,'visible':false},
  //     { path: '/GLA', title: 'GLA',  icon:'ni-single-copy-04 text-purple', class: '' ,stepNo:'8','visible':false},
  //     { path: '/lease-payment', title: 'Lease Payment  ',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'9' ,'visible':false},
  //     { path: '/BP-application', title: 'BP-Application ',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'10','visible':false},
  //     { path: '/material-delivery-confirmation', title: 'Material delivery confirmation',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'11','visible':false},
  //     { path: '/tool-box-meeting', title: 'Tool Box Meeting',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'12','visible':false},
  //     { path: '/fencing', title: 'Fencing ',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'13','visible':false},
  //     { path: '/solar-structure',   title: 'Solar Structure ',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'14','visible':false},
  //     { path: '/genset-platform',   title: 'Genset Platform ',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'15','visible':false},
  //     { path: '/first-pole-installation',   title: 'First Pole Installation',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'16','visible':false},
  //     { path: '/fable-trenching',   title: 'Cable Trenching',  icon:'ni-single-copy-04 text-purple', class: '' ,stepNo:'17','visible':false},
  //     { path: '/earth-pit',         title: 'Earth Pit ',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'18','visible':false},
  //     { path: '/power-equipment-installation',   title: 'Power Equipment Installation',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'19','visible':false},
  //     { path: '/PV-installation',   title: 'PV Installation ',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'20','visible':false},
  //     { path: '/battery-installation',          title: 'Battery Installation ',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'21','visible':false},
  //     { path: '/genset-Installation',           title: 'Genset Installation',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'22','visible':false},
  //     { path: '/cabling-installation',          title: 'Cabling Installation',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'23','visible':false},
  //     { path: '/pre-commissioning-and-fac-submission', title: 'PAC and FAC',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'24' ,'visible':false},
  //     { path: '/pole-and-Cabling',           title: 'Pole and Cabling',  icon:'ni-single-copy-04 text-purple', class: '' ,stepNo:'26','visible':false},
  //     { path: '/tower-end-equipment-installation-and-cabling',           title: 'Tower End Equipment Installation And Cabling',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'27' ,'visible':false},
  //     { path: '/asset-Tracking-and-Surveillance-TEE-installation',           title: 'Asset Tracking and Surveillance TEE Installation',  icon:'ni-single-copy-04 text-purple', class: '' ,stepNo:'28','visible':false},
  //     { path: '/asset-Verification-and-Surveillance-Power-Plant',           title: 'Asset Verification and Surveillance Power Plant',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'29' ,'visible':false},
  //     { path: '/site-commissioning', title: 'Site commissioning',  icon:'ni-single-copy-04 text-purple', class: '',stepNo:'30' ,'visible':false},
  //     { path: '/ready-for-Utilization-date', title: 'Ready for Utilization Date',  icon:'ni-single-copy-04 text-purple', class: '' ,stepNo:'31','visible':false},
  //     { path: '/commercial-Operation-date', title: 'commercial Operation Date',  icon:'ni-single-copy-04 text-purple', class: '' ,stepNo:'32','visible':false},
  // ];

  baseUrl = "" + environment.devBaseAPIUrl;

  getCurrentUser(): any {
    return this.currentUserSubject.value;
  }

  setAccess(stepNo) {
    return {
      "read_access": false,
      "write_access": false,
      "edit_access": false,
      "delete_access": false,
      "approval_access": false
    };

    let accessObj = {
      "read_access": false,
      "write_access": false,
      "edit_access": false,
      "delete_access": false,
      "approval_access": false
    };
    let user = localStorage.getItem("userId");
    let userObj = JSON.parse(localStorage.getItem("userObj"));
    let url = "roles/findByRoleId";
    return accessObj;
  }

  //   getRouteInfo(){
  //     return this.routesInfo;
  //   }

  setAccess1(stepNo, currentUserRole?: any) {
    this.currentUserRole = JSON.parse(localStorage.getItem('userRole'))
    console.log(":: getting the value from currentuser role", this.currentUserRole);
    console.log(":: step", stepNo);
    stepNo = "" + stepNo;
    // console.log("setting access for step No: " + stepNo);

    let accessObj = {
      "read_access": false,
      "write_access": false,
      "edit_access": false,
      "delete_access": false,
      "approval_access": false
    };

    if (this.currentUserRole.write_access.indexOf(stepNo) != -1) {
      accessObj['write_access'] = true;
    }
    if (this.currentUserRole.approval_access.indexOf(stepNo) != -1) {
      accessObj['approval_access'] = true;
    }
    if (this.currentUserRole.edit_access.indexOf(stepNo) != -1) {
      accessObj['edit_access'] = true;
    }
    if (this.currentUserRole.delete_access.indexOf(stepNo) != -1) {
      accessObj['delete_access'] = true;
    }
    if (this.currentUserRole.read_access.indexOf(stepNo) != -1) {
      accessObj['read_access'] = true;
    }
    return accessObj;
  }

  getAccessObj() {
    let userObj = JSON.parse(localStorage.getItem("userObj"));
    let url = "roles/findByRoleId";

    const body = new HttpParams()
      .set("roleId", userObj['rollId']);

    return this.http
      .post(
        this.baseUrl + "sitehandler/api/" + url,
        body
      )
      .pipe(take(1), map((response: any) => {
        this.accessObjResponse = response;
        this.currentUserRole = {
          approval_access: response['result'].approvalAccess,
          write_access: response['result'].writeAccess,
          read_access: response['result'].readAccess,
          edit_access: response['result'].editAccess,
          delete_access: response['result'].deleteAccess,
          role_id: response['result'].roleId,
          roleName: response['result'].roleName
        }
        localStorage.setItem('userRole', JSON.stringify(this.currentUserRole));
        console.log(":: ste the current user value", this.currentUserRole)
        this.currentUserSubject.next(response);
        return response;
      }));
  }

  fileUpload(fileItem: File): any {

    let apiCreateEndpoint =
      this.baseUrl + "sitehandler/api/sites/upload_sites";
    const formData: FormData = new FormData();

    formData.append("file", fileItem);

    const req = new HttpRequest("POST", apiCreateEndpoint, formData, {
      reportProgress: true // for progress data
    });
    return this.http.request(req);
  }

  updateSiteMap(siteObj): any {

    const params = new HttpParams()
      .set("customerCode", siteObj.customerCode)
      .set("towerLatitude", siteObj.towerLatitude)
      .set("towerLongitude", siteObj.towerLongitude)
      .set("region", siteObj.region)
      .set("township", siteObj.township)
      .set("proposedLeaseAreaType", siteObj.proposedLeaseAreaType)
      .set("ympSiteCode", siteObj.ympSiteCode)

    const formData: FormData = new FormData();
    formData.append("multipartFile", siteObj.fileToUpload);

    console.log("file is uploading,fileToUpload", siteObj.fileToUpload);
    return this.http
      .post(
        this.baseUrl + "sitehandler/api/sites/uploadSiteMapImage",
        formData,
        { params: params }
      )
      .pipe(map((response: any) => {
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));
  }

  updateSite(siteObj): any {
    let apiCreateEndpoint = this.baseUrl + "sitehandler/api/sites/update-sites";

    const body = new HttpParams()
      .set("customerName", siteObj.customerName)
      .set("ympSiteCode", siteObj.ympSiteCode)
      .set("id", siteObj.id)
      //.set('dateOfVerification', siteObj.dateOfVerification)
      .set("region", siteObj.region)
      .set("siteAddressVerified", siteObj.siteAddressVerified)
      .set("ownerNameVerified", siteObj.ownerNameVerified)
      .set("ownerAddressVerified", siteObj.ownerAddressVerified)
      .set("ownerContactNumberVerified", siteObj.ownerContactNumberVerified)
      .set("powerAvailableVerified", siteObj.powerAvailableVerified)
      .set("gridSupplyVerified", siteObj.gridSupplyVerified)
      .set("statusVerified", siteObj.statusVerified)
      .set("teleVeriRemarks", siteObj.teleVeriRemarks);

    return this.http
      .post(
        this.baseUrl + "sitehandler/api/sites/update-sites",
        siteObj
      )
      .pipe(map((response: any) => {
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));
  }

  updateVendor(vendorObj): any {
    let apiCreateEndpoint =
      this.baseUrl + "sitehandler/api/sites/update-sites";

    const myheader = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );

    const body = new HttpParams()
      .set("customerCode", vendorObj.customerCode)
      .set("ympSiteCode", vendorObj.ympSiteCode)
      .set("id", vendorObj.id)
      .set("candidateLatitude", vendorObj.candidateLatitude)
      .set("region", vendorObj.region)
      .set("candidateLongitude", vendorObj.candidateLongitude)
      .set("vendorBatches", vendorObj.vendorBatches)
      .set("vendorStatus", vendorObj.vendorStatus)
      .set("vndrRemarks", vendorObj.vndrRemarks)
      .set("vndrSubcon", vendorObj.vndrSubcon)
      .set("vndrDateOfAllocation", vendorObj.vndrDateOfAllocation)
      .set("remarksFromVendor", vendorObj.remarksFromVendor)
      .set("vndrSPOCFromVendor", vendorObj.vndrSPOCFromVendor)
      .set("vndrSPOCContactNumber", vendorObj.vndrSPOCContactNumber)
      .set("vndrSAQManagerName", vendorObj.vndrSAQManagerName)
      .set("vndrSAQManagerContact", vendorObj.vndrSAQManagerContact)
      .set("vndrAssignedSAQOfficerName", vendorObj.vndrAssignedSAQOfficerName)
      .set("vndrAssignedSAQOfficerContact", vendorObj.vndrAssignedSAQOfficerContact)
      .set("vndrSubconDataRemarks", vendorObj.vndrSubconDataRemarks)
      .set("vndrSAQMileston", vendorObj.vndrSAQMileston);

    return this.http
      .post(
        this.baseUrl + "sitehandler/api/sites/sites-verify",
        body
      )
      .pipe(map((response: any) => {
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));
  }

  getDashboardSiteCounts() {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    return this.http
      .get(this.baseUrl + "sitehandler/api/sites/site-dashboard")
      .pipe(map((response: any) => response));
  }

  getAllSiteCodes() {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    return this.http
      .get(this.baseUrl + "sitehandler/api/sites/all-sites-code")
      .pipe(map((response: any) => response));
  }

  getAllSiteCodesUsingStpId(myparams) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");

    return this.http
      .get(this.baseUrl + "sitehandler/api/sites/filterAllSiteCodeByStepId", {
        headers: {},
        params: myparams
      })
      .pipe(map((response: any) => response));
  }

  getSelectedSite(sitecode) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .get(
        this.baseUrl + "sitehandler/api/sites/sites-details?sitesCode=" +
        sitecode,
        options
      )
      .pipe(map((response: any) => response));
  }
  getTssrSelectedSite(sitecode) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .get(
        this.baseUrl + "sitehandler/api/filter-all-shTssr?page=0&size=50000&ympSiteCode=" +
        sitecode,
        options
      )
      .pipe(map((response: any) => response));
  }

  getAllSites(obj) {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    let params = new URLSearchParams();
    params.append("size", obj.pageSize);
    params.append("page", obj.pageNumber);

    const req = new HttpRequest(
      "GET",
      this.baseUrl + "sitehandler/api/sites/all-sites?page=" +
      obj.pageNumber +
      "&size=" +
      obj.pageSize,
      {
        reportProgress: true // for progress data
      }
    );

    return this.http.request(req);
  }

  getActivitySteps(stepNo) {
    let pageNumber = 0;
    let pageSize = 100000;

    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };

    return this.http
      .get(
        this.baseUrl + "sitehandler/api/all-report?page=" +
        pageNumber +
        "&size=" +
        pageSize +
        "&stepNo=" + stepNo,
        options
      )
      .pipe(map((response: any) => response));
  }

  getReqWithFilter(url, params) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .get(
        this.baseUrl + "sitehandler/api/" + url,
        { params: params }
      )
      .pipe(map((response: any) => response));
  }

  getReqWithoutFilter(url, pageNumber, pageSize) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .get(
        this.baseUrl + "sitehandler/api/" + url + "page=" +
        pageNumber +
        "&size=" +
        pageSize,
      )
      .pipe(map((response: any) => response));
  }

  getReqForcount(url) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    const body = {}
    return this.http
      .post(
        this.baseUrl + "sitehandler/api/" + url, body)
      .pipe(map((response: any) => response));
  }

  pacfacandPrecommision(url, stepno, pageNumber, pageSize) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .get(
        this.baseUrl + "sitehandler/api/" + url + "page=" +
        pageNumber +
        "&size=" +
        pageSize +
        "&stepNo=" +
        stepno,
      )
      .pipe(map((response: any) => response));
  }
  savetoolboxMeeting(url, data) {
    return this.http
      .post(
        this.baseUrl + "sitehandler/api/" + url,
        data
      )
      .pipe(map((response: any) => {
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));
  }
  getReqWithoutFilterForProjectVendor(url) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .get(
        this.baseUrl + "sitehandler/api/" + url,
        options
      )
      .pipe(map((response: any) => response));
  }
  siteMap(url, obj) {
    return this.http
      .post(
        this.baseUrl + "sitehandler/api/" + url,
        obj
      )
      .pipe(map((response: any) => {
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));
  }

  getPlotsOptions(code) {
    return this.http
      .get(
        this.baseUrl + "sitehandler/api/filter-options-plots?ympsitecode=" + code
      )
      .pipe(map((response: any) => response));
  }

  emailNotification(approvalRequest) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .post(this.baseUrl + "sitehandler/api/notification/email-notification", approvalRequest)
      .pipe(map((response: any) => {
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));
  }

  updateUploadFile(url, params, fileToUpload): any {
    const formData: FormData = new FormData();
    formData.append("uploadFile", fileToUpload);

    return this.http
      .post(
        this.baseUrl + "sitehandler/api/" + url,
        formData, { params: params }
      )
      .pipe(map((response: any) => {
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));
  }

  datawithFilePreSet(url, params, formdata): any {
    return this.http
      .post(
        this.baseUrl + "sitehandler/api/" + url,
        formdata, { params: params }
      )
      .pipe(map((response: any) => {
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));
  }

  uploadVendorFile(url, fileToUpload): any {
    const formData: FormData = new FormData();
    formData.append("uploadFile", fileToUpload);

    return this.http
      .post(
        this.baseUrl + "sitehandler/api/" + url,
        formData,
      )
      .pipe(map((response: any) => {
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));
  }

  uploadAnyImage(url, fileToUpload, oldPath): any {
    const formData: FormData = new FormData();
    formData.append("multipartFile", fileToUpload);

    return this.http
      .post(
        this.baseUrl + "sitehandler/api/" + url + "?ImgPath=" + oldPath + "&ympSiteCode=ddf",
        formData,
      )
      .pipe(map((response: any) => {
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));
  }

  updateUploadFileStepTwelve(url, params, fileToUpload): any {
    const formData: FormData = new FormData();
    formData.append("multipartFileshToolBoxS12", fileToUpload);

    return this.http
      .post(
        this.baseUrl + "sitehandler/api/" + url,
        formData,
        { params: params }
      )
      .pipe(map((response: any) => {
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));
  }

  deleteReq(url, id, ympSiteCode) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .delete(this.baseUrl + "sitehandler/api/" + url + id + "&ympSiteCode=" + ympSiteCode, options).pipe(map((response: any) => response));
  }

  deletesharedstep(url, id, ympSiteCode, stepNo) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .delete(this.baseUrl + "sitehandler/api/" + url + id + "&stepNo=" + stepNo + "&ympSiteCode=" + ympSiteCode, options).pipe(map((response: any) => response));
  }

  deleteReqActivity(url, id, ympSiteCode, stepNo) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .delete(
        this.baseUrl + "sitehandler/api/" + url +
        id +
        "&ympSiteCode=" +
        ympSiteCode +
        "&stepNo=" +
        stepNo,
        options
      )
      .pipe(map((response: any) => response));
  }


  postReq2nd(url, obj, query) {

    return this.http
      .post(
        this.baseUrl + "sitehandler/api/" + url,
        obj
      )
      .pipe(map((response: any) => {
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));
  }

  postReq(url, obj) {

    return this.http
      .post(
        this.baseUrl + "sitehandler/api/" + url,
        obj
      )
      .pipe(map((response: any) => {
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));
  }

  login(url, obj) {

    return this.http
      .post(
        this.baseUrl + "sitehandler/api/" + url,
        obj
      )
      .pipe(map((response: any) => {
        sessionStorage.setItem('isLogged', 'true');
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));
  }


  isLoggedIn() {
    console.log(!!sessionStorage.getItem('isLogged'))
    return !!sessionStorage.getItem('isLogged');
  }

  getAllSiteReviews() {
    let pageNumber = 0;
    let pageSize = 100000;

    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .get(
        this.baseUrl + "sitehandler/api/all-grid-report?page=" +
        pageNumber +
        "&size=" +
        pageSize,
        options
      )
      .pipe(map((response: any) => response));
  }
  getResultWithPagination(url: string, pageNumber: number, pageSize: number) {


    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .get(
        this.baseUrl + "sitehandler/api/" + url + "?page=" +
        pageNumber +
        "&size=" +
        pageSize,
        options
      )
      .pipe(map((response: any) => response));
  }

  saveReport(siteObj): any {

    return this.http
      .post(
        this.baseUrl + `sitehandler/api/technical-site-survey-report?finalSubmission=${true}`,
        siteObj
      )
      .pipe(map((response: any) => {
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));

  }
  saveOtherDocument(siteObj): any {
    siteObj['formApproval'] = "";
    return this.http
      .post(
        this.baseUrl + "sitehandler/api/shOtherWays/shOtherWaysSave?imgNo=''",
        siteObj
      )
      .pipe(map((response: any) => {
        return response
      }), catchError(
        (error, caught): ObservableInput<any> => {
          console.log(error);
          if (error.error.message) {
            this.openSnack(error.error.message, 'OK');
          }
          return throwError(error);
        },
      ));

  }
  getAllMRN(value, page = 0, size = 10) {
    let pageNumber = page;
    let pageSize = size;

    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .get(
        this.baseUrl + `sitehandler/api/mrn/all-mrn-dispatched-received?${value}&page=${pageNumber}&size=${pageSize}`, options
      )
      .pipe(map((response: any) => response));
  }

  getAllMARNDispatch(page, size) {
    let pageNumber = page;
    let pageSize = size;
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    console.log("::::: this.baseUrl +", this.baseUrl)
    return this.http
      .get(
        this.baseUrl + `/sitehandler/api/mrn/all-mrn?page=${page}&size=${size}`
      )
      .pipe(map((response: any) => response));
  }

  httpGet(
    queryUrl: string,
    myparams: any): Observable<any> {
    const url = this.baseUrl + queryUrl;
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .get(url, {
        headers: headers,
        params: myparams
      })
      .pipe(
        tap((data: any) => {
          console.log(data);
          return data.data;
        }),
        catchError((error: any) => {
          return error;
        })
      );
  }



  mrnReport(siteObj): any {
    return this.http.post(this.baseUrl + "sitehandler/api/mrn/mrnsave", siteObj).pipe(map((response: any) => {
      return response
    }), catchError(
      (error, caught): ObservableInput<any> => {
        console.log(error);
        if (error.error.message) {
          this.openSnack(error.error.message, 'OK');
        }
        return throwError(error);
      },
    ));
  }

  mrnreportstepsData(sitecode) {
    return this.http.post(this.baseUrl + `sitehandler/api/checkStepHunter-new?ympSiteCode=${sitecode}`, { sitecode: '' }).pipe(map((response: any) => {
      return response
    }), catchError(
      (error, caught): ObservableInput<any> => {
        console.log(error);
        if (error.error.message) {
          this.openSnack(error.error.message, 'OK');
        }
        return throwError(error);
      },
    ));
  }

  getmrnReports(url, pageNumber, pageSize) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = { headers: headers };
    return this.http.get(this.baseUrl + "sitehandler/api/" + url + "page=" + pageNumber + "&size=" + pageSize, options).pipe(map((response: any) => response));
  }

  deletemrnReport(id, ympSiteCode) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .delete(
        this.baseUrl + "sitehandler/api/mrn/delete-mrn?id=" + id + "&ympSiteCode=" + ympSiteCode, options
      )
      .pipe(map((response: any) => response));
  }


  deletemastertable(id, vendorCompany) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .delete(
        this.baseUrl + "sitehandler/api/delete-ShMaster?id=" + id + "&vendorCompany=" + vendorCompany, options
      )
      .pipe(map((response: any) => response));
  }

  getReq(obj) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .get(
        this.baseUrl + "sitehandler/api/sites/all-sites?page=" +
        obj.pageNumber +
        "&size=" +
        obj.pageSize,
        options
      )
      .pipe(map((response: any) => response));
  }

  deleteSite(id, ympSiteCode) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .delete(
        this.baseUrl + "sitehandler/api/sites/delete-sites-by-sitescode?id=" +
        id +
        "&ympSiteCode=" +
        ympSiteCode,
        options
      )
      .pipe(map((response: any) => response));
  }

  deleteSurvey(id, ympSiteCode) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .delete(
        this.baseUrl + "sitehandler/api/delete-technical-site-survey-report?id=" +
        id,
        options
      )
      .pipe(map((response: any) => response));
  }

  deleteUser(url, id) {
    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .delete(
        this.baseUrl + "sitehandler/api/" + url + id, options
      )
      .pipe(map((response: any) => response));
  }

  async getBase64ImageFromUrl(imageUrl) {
    var res = await fetch(imageUrl, { mode: 'cors', });
    var blob = await res.blob();

    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener("load", function () {
        resolve(reader.result);
      }, false);

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })



    // this.dataService.getBase64ImageFromUrl("http://connect.link2farm.com:8080/sitehandler/api/sites/downloadFile/ddf_1594304049764_DG.jpg").then(result => {
    //   console.log(result)
    // })
    //   .catch(err => console.error(err));
  }




  getAllRoles() {
    let pageNumber = 0;
    let pageSize = 100000;

    const headers_content = {
      "Content-Type": "application/json"
    };
    const headers = new HttpHeaders(headers_content);
    const options = {
      headers: headers
    };
    return this.http
      .get(
        this.baseUrl + `sitehandler/api/roles/all-role-details?page=0&size=100000`, options
      )
      .pipe(map((response: any) => response));
  }






  exportExcel(excelData) {

    //Title, Header & Data
    const title = excelData.title;
    // const header = excelData.headers
    // const data = excelData.data;

    //Create a workbook with a worksheet
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet('Report Summary');


    //Add Row and formatting
    worksheet.mergeCells('A1', 'F1');
    let titleRow = worksheet.getCell('A1');
    titleRow.value = title
    titleRow.font = {
      name: 'Calibri',
      size: 16,
      underline: 'single',
      bold: true,
      color: { argb: '0085A3' }
    }
    titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

    // Date
    // worksheet.mergeCells('G1:H4');
    // let d = new Date();
    // let date = d.getDate() + '-' + d.getMonth() + '-' + d.getFullYear();
    // let dateCell = worksheet.getCell('G1');
    // dateCell.value = date;
    // dateCell.font = {
    //   name: 'Calibri',
    //   size: 12,
    //   bold: true
    // }
    // dateCell.alignment = { vertical: 'middle', horizontal: 'center' }

    //Add Image
    // let myLogoImage = workbook.addImage({
    //   base64: logo.imgBase64,
    //   extension: 'png',
    // });
    // worksheet.mergeCells('A1:B4');
    // worksheet.addImage(myLogoImage, 'A1:B4');

    //Blank Row 
    worksheet.addRow([]);







    worksheet.mergeCells('A3', 'D3');
    let titlesiteTeleVerificationRow = worksheet.getCell('A3');
    titlesiteTeleVerificationRow.value = 'Site Tele Verification'
    titlesiteTeleVerificationRow.font = {
      name: 'Calibri',
      size: 16,
      // underline: 'single',
      bold: true,
      color: { argb: '525f7f' }
    }
    titlesiteTeleVerificationRow.alignment = { vertical: 'middle', horizontal: 'center' }

    // worksheet.addRow([]);
    let siteTeleVerificationRow = worksheet.addRow(excelData.data.siteTeleVerification.headers);
    siteTeleVerificationRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });
    siteTeleVerificationRow.alignment = { vertical: 'middle', horizontal: 'center' }
    let siteTeleVerificationCell = worksheet.addRow(excelData.data.siteTeleVerification.data);
    siteTeleVerificationCell.alignment = { vertical: 'middle', horizontal: 'center' }


    // new row 

    worksheet.addRow([]);
    worksheet.mergeCells('A7', 'D7');
    let titletechnicalSiteSurveyReport = worksheet.getCell('A7');
    titletechnicalSiteSurveyReport.value = 'Technical Site Survey Report'
    titletechnicalSiteSurveyReport.font = {
      name: 'Calibri',
      size: 16,
      // underline: 'single',
      bold: true,
      color: { argb: '525f7f' }
    }
    titletechnicalSiteSurveyReport.alignment = { vertical: 'middle', horizontal: 'center' }



    let technicalSiteSurveyReportRow = worksheet.addRow(excelData.data.technicalSiteSurveyReport.headers);
    technicalSiteSurveyReportRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });
    technicalSiteSurveyReportRow.alignment = { vertical: 'middle', horizontal: 'center' }
    let technicalSiteSurveyReportCell = worksheet.addRow(excelData.data.technicalSiteSurveyReport.data);
    technicalSiteSurveyReportCell.alignment = { vertical: 'middle', horizontal: 'center' }


    // new row 

    worksheet.addRow([]);

    worksheet.mergeCells('A11', 'D11');
    let titlecadDrawing = worksheet.getCell('A11');
    titlecadDrawing.value = 'CAD Drawing'
    titlecadDrawing.font = {
      name: 'Calibri',
      size: 16,
      // underline: 'single',
      bold: true,
      color: { argb: '525f7f' }
    }
    titlecadDrawing.alignment = { vertical: 'middle', horizontal: 'center' }


    let cadDrawingtRow = worksheet.addRow(excelData.data.cadDrawing.headers);
    cadDrawingtRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });
    cadDrawingtRow.alignment = { vertical: 'middle', horizontal: 'center' }
    let cadDrawingCell = worksheet.addRow(excelData.data.cadDrawing.data);
    cadDrawingCell.alignment = { vertical: 'middle', horizontal: 'center' }


    // new row 

    worksheet.addRow([]);
    worksheet.mergeCells('A15', 'D15');
    let titleprojectVendorAllocationt = worksheet.getCell('A15');
    titleprojectVendorAllocationt.value = 'Project Vendor Allocation'
    titleprojectVendorAllocationt.font = {
      name: 'Calibri',
      size: 16,
      // underline: 'single',
      bold: true,
      color: { argb: '525f7f' }
    }
    titleprojectVendorAllocationt.alignment = { vertical: 'middle', horizontal: 'center' }

    let projectVendorAllocationtRow = worksheet.addRow(excelData.data.projectVendorAllocation.headers);
    projectVendorAllocationtRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });
    projectVendorAllocationtRow.alignment = { vertical: 'middle', horizontal: 'center' }
    let projectVendorAllocationCell = worksheet.addRow(excelData.data.projectVendorAllocation.data);
    projectVendorAllocationCell.alignment = { vertical: 'middle', horizontal: 'center' }


    // new row 

    worksheet.addRow([]);
    worksheet.mergeCells('A19', 'D19');
    let titlemrnReport = worksheet.getCell('A19');
    titlemrnReport.value = 'MRN Report'
    titlemrnReport.font = {
      name: 'Calibri',
      size: 16,
      // underline: 'single',
      bold: true,
      color: { argb: '525f7f' }
    }
    titlemrnReport.alignment = { vertical: 'middle', horizontal: 'center' }


    let mrnReportRow = worksheet.addRow(excelData.data.mrnReport.headers);
    mrnReportRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });
    mrnReportRow.alignment = { vertical: 'middle', horizontal: 'center' }
    let mrnReportCell = worksheet.addRow(excelData.data.mrnReport.data);
    mrnReportCell.alignment = { vertical: 'middle', horizontal: 'center' }


    // new row 

    worksheet.addRow([]);
    worksheet.mergeCells('A23', 'D23');
    let titlepreCommission = worksheet.getCell('A23');
    titlepreCommission.value = 'Pre Commisioning'
    titlepreCommission.font = {
      name: 'Calibri',
      size: 16,
      // underline: 'single',
      bold: true,
      color: { argb: '525f7f' }
    }
    titlepreCommission.alignment = { vertical: 'middle', horizontal: 'center' }

    let preCommissionRow = worksheet.addRow(excelData.data.preCommission.headers);
    preCommissionRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });
    preCommissionRow.alignment = { vertical: 'middle', horizontal: 'center' }
    let preCommissionCell = worksheet.addRow(excelData.data.preCommission.data);
    preCommissionCell.alignment = { vertical: 'middle', horizontal: 'center' }


    // new row 

    worksheet.addRow([]);
    worksheet.mergeCells('A27', 'D27');
    let titlesiteCommissioning = worksheet.getCell('A27');
    titlesiteCommissioning.value = 'Site Commisioning'
    titlesiteCommissioning.font = {
      name: 'Calibri',
      size: 16,
      // underline: 'single',
      bold: true,
      color: { argb: '525f7f' }
    }
    titlesiteCommissioning.alignment = { vertical: 'middle', horizontal: 'center' }

    let siteCommissioningRow = worksheet.addRow(excelData.data.siteCommissioning.headers);
    siteCommissioningRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });
    siteCommissioningRow.alignment = { vertical: 'middle', horizontal: 'center' }
    let siteCommissioningCell = worksheet.addRow(excelData.data.siteCommissioning.data);
    siteCommissioningCell.alignment = { vertical: 'middle', horizontal: 'center' }


    // new row 

    worksheet.addRow([]);
    worksheet.mergeCells('A31', 'D31');
    let titlereadyForUtilization = worksheet.getCell('A31');
    titlereadyForUtilization.value = 'Ready For Utilization Date'
    titlereadyForUtilization.font = {
      name: 'Calibri',
      size: 16,
      // underline: 'single',
      bold: true,
      color: { argb: '525f7f' }
    }
    titlereadyForUtilization.alignment = { vertical: 'middle', horizontal: 'center' }

    let readyForUtilizationRow = worksheet.addRow(excelData.data.readyForUtilization.headers);
    readyForUtilizationRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });
    readyForUtilizationRow.alignment = { vertical: 'middle', horizontal: 'center' }
    let readyForUtilizationCell = worksheet.addRow(excelData.data.readyForUtilization.data);
    readyForUtilizationCell.alignment = { vertical: 'middle', horizontal: 'center' }

    // new row 

    worksheet.addRow([]);
    worksheet.mergeCells('A35', 'D35');
    let titlecommercialOperation = worksheet.getCell('A35');
    titlecommercialOperation.value = 'Commercial Operation Date'
    titlecommercialOperation.font = {
      name: 'Calibri',
      size: 16,
      // underline: 'single',
      bold: true,
      color: { argb: '525f7f' }
    }
    titlecommercialOperation.alignment = { vertical: 'middle', horizontal: 'center' }

    let commercialOperationRow = worksheet.addRow(excelData.data.commercialOperation.headers);
    commercialOperationRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    });
    commercialOperationRow.alignment = { vertical: 'middle', horizontal: 'center' }
    let commercialOperationCell = worksheet.addRow(excelData.data.commercialOperation.data);
    commercialOperationCell.alignment = { vertical: 'middle', horizontal: 'center' }


    worksheet.getColumn(3).width = 20;
    worksheet.addRow([]);

    //Generate & Save Excel File
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, title + '.xlsx');
    })

  }


  openSnack(message: string, label: string) {
    // this.snackBar.open(message, label, {
    //   duration: 2000
    // });
    // if (message.indexOf('ShProjectHunter') >= 0) {
    //   message = 'Project Hunter data not available'
    // }
    // if (message.indexOf('ShVendorAllocation') >= 0) {
    //   message = 'Vendor Allocation data not available'
    // }
    // if (message.indexOf('ShsitehunterS4') >= 0) {
    //   message = 'Hunter Allocation data not available'
    // }
    if (message.indexOf('No message available') >= 0) {
      message = 'There is an error in the form !!!'
    } else {
      message = 'Data not available'
    }

    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
      // footer: '<a href>Why do I have this issue?</a>'
    })
  }


  setLoggedInVal(value: boolean) {
    this.loggedIn.next(value);
  }
  get findIsLoggedIn() {
    return this.loggedIn.asObservable();
  }


  canAccessUrl(requestedUrl: string) {

  }

}
